.pdf-light-viewer-archive {
  padding: 15px; }
  .pdf-light-viewer-archive .site-main {
    padding: 15px; }
  .pdf-light-viewer-archive .pdf-light-viewer-features-top-panel {
    list-style: none;
    position: static;
    text-align: left;
    border-radius: 6px 6px 0 0;
    margin-bottom: 16px; }
    .pdf-light-viewer-archive .pdf-light-viewer-features-top-panel .current-cat {
      font-weight: bold; }
  .pdf-light-viewer-archive .pdf-light-viewer-grid {
    position: relative; }
    .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item {
      margin-bottom: 15px; }
      .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner {
        position: relative; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .transition {
          transition: 0.3s cubic-bezier(0.3, 0, 0, 1.3); }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card {
          background-color: #fff;
          bottom: 0;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          height: 250px;
          left: 0;
          margin: auto;
          overflow: hidden;
          position: relative;
          right: 0;
          top: 0;
          width: 200px; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover .btn-container {
          display: inline;
          margin-top: 180px; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover .card_circle {
          background-size: cover;
          border-radius: 0;
          margin-top: -180px; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover h2 {
          background: RoyalBlue;
          color: #fff;
          margin-top: 100px;
          padding: 5px; }
          .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover h2 a {
            color: #fff; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover h2 small {
          color: #fff; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card_circle {
          background: no-repeat center bottom;
          background-color: RoyalBlue;
          background-size: cover;
          border-radius: 50%;
          height: 250px;
          margin-left: -25px;
          margin-top: -110px;
          position: absolute;
          width: 250px; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner h2 {
          color: RoyalBlue;
          font-size: 24px;
          line-height: 28px;
          font-weight: 200;
          margin-top: 150px;
          position: absolute;
          text-align: center;
          width: 100%;
          z-index: 9999; }
          .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner h2 a {
            color: RoyalBlue; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .btn-container {
          display: none;
          margin-top: 320px;
          position: absolute;
          text-align: center;
          width: 100%;
          z-index: 9999; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .btn-pdf {
          -moz-border-radius: 2px;
          -moz-transition: 0.2s ease-out;
          -ms-transition: 0.2s ease-out;
          -o-transition: 0.2s ease-out;
          -webkit-border-radius: 2px;
          -webkit-transition: 0.2s ease-out;
          background-clip: padding-box;
          border: 2px solid RoyalBlue;
          border-radius: 2px;
          color: RoyalBlue;
          display: inline-block;
          font-size: 17px;
          font-weight: 400;
          height: 36px;
          letter-spacing: 0.5px;
          line-height: 36px;
          margin-bottom: 15px;
          padding: 0 2rem;
          text-decoration: none;
          text-transform: uppercase;
          transition: 0.2s ease-out; }
        .pdf-light-viewer-archive .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .btn-pdf:hover {
          background-color: RoyalBlue;
          color: #fff;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.pdf-light-viewer-search-container {
  width: 20%;
  float: left;
  margin-bottom: 10px; }
  .pdf-light-viewer-search-container .pdf-light-viewer-search-input {
    width: 79%;
    float: left;
    background-image: linear-gradient(to bottom, #ffffff 0%, #fefefe 57%, #f6f6f6 100%);
    border-radius: 6px;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: RoyalBlue;
    padding: 10px 5px; }
  .pdf-light-viewer-search-container .pdf-light-viewer-search-button {
    width: 19%;
    margin-left: 2%;
    float: left;
    background-image: linear-gradient(to bottom, #ffffff 0%, #fefefe 57%, #f6f6f6 100%);
    border-radius: 6px;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: RoyalBlue;
    padding: 11px; }

.pdf-light-viewer-search-container-full {
  width: 100%;
  float: left;
  margin-bottom: 20px; }
  .pdf-light-viewer-search-container-full .pdf-light-viewer-search-input {
    width: 92%;
    float: left;
    background-image: linear-gradient(to bottom, #ffffff 0%, #fefefe 57%, #f6f6f6 100%);
    border-radius: 6px;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: RoyalBlue;
    padding: 10px 5px; }
  .pdf-light-viewer-search-container-full .pdf-light-viewer-search-button {
    width: 5%;
    margin-left: 1%;
    float: left;
    background-image: linear-gradient(to bottom, #ffffff 0%, #fefefe 57%, #f6f6f6 100%);
    border-radius: 6px;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: RoyalBlue;
    padding: 11px; }

.pdf-light-viewer-search-results .pdf-light-viewer--clear {
  padding-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid LightGray; }

.pdf-light-viewer-results-item-pdf {
  width: 29%;
  float: left; }

.pdf-light-viewer-results-item-pages {
  width: 69%;
  margin-left: 2%;
  float: left;
  text-align: left; }
  .pdf-light-viewer-results-item-pages a {
    display: block;
    margin-bottom: 10px; }
    .pdf-light-viewer-results-item-pages a div {
      font-size: 0.8em; }

.pdf-light-viewer-search-results-found {
  color: RoyalBlue;
  font-weight: bold; }

html.pdf-light-viewer-reset {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden; }
  html.pdf-light-viewer-reset body {
    background: none;
    overflow: hidden; }
    html.pdf-light-viewer-reset body:after, html.pdf-light-viewer-reset body:before {
      display: none; }

.pdf-light-viewer-archive-container {
  box-sizing: border-box; }

.pdf-light-viewer-pdf-view {
  box-sizing: border-box;
  width: 79%;
  float: left;
  margin-right: 1%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pdf-light-viewer-pdf-view {
      float: none;
      width: 100%; } }
  .pdf-light-viewer-pdf-view .pdf-light-viewer-view-iframe {
    width: 100%;
    border: none;
    overflow: hidden;
    min-height: 300px; }

.pdf-light-viewer-pdf-select {
  box-sizing: border-box;
  width: 20%;
  float: left;
  background-image: linear-gradient(to bottom, #ffffff 0%, #fefefe 57%, #f6f6f6 100%);
  border-radius: 6px;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 3px 8px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pdf-light-viewer-pdf-select {
      float: none;
      width: 100%; } }
  .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories {
    list-style: none;
    margin: 5px 0 0;
    padding: 5px 10px;
    text-align: left; }
    .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li {
      margin: 0;
      padding: 5px; }
      .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a {
        width: 100%;
        display: block;
        color: RoyalBlue; }
        .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a .pdf-light-viewer--counter {
          color: white;
          background-color: RoyalBlue;
          border-radius: 5px;
          margin: 4px 0 10px;
          padding: 0 5px;
          font-size: 12px; }
        .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a .pdf-light-viewer-grid-item-content {
          display: inline-block; }
        .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a .d-ib {
          display: inline-block; }
        .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a div {
          margin: 4px 0 10px;
          padding: 0 5px;
          font-size: 12px; }
      .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li .pdf-light-viewer-pdf-categories {
        display: none;
        border-bottom: 1px solid LightGray;
        max-height: 300px;
        overflow: auto; }
    .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories .pdf-light-viewer-selected {
      font-weight: bold;
      color: RoyalBlue;
      position: relative; }
      .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories .pdf-light-viewer-selected:before {
        font-family: "simple-line-icons";
        content: "\E606";
        color: RoyalBlue;
        font-size: 12px;
        position: absolute;
        left: -15px;
        top: 0; }
  .pdf-light-viewer-pdf-select .pdf-light-viewer-grid-item .js-pdf-light-viewer-display-pdf span {
    font-size: 12px; }
  .pdf-light-viewer-pdf-select .pdf-light-viewer-grid-item .js-pdf-light-viewer-display-pdf img {
    margin-top: 10px; }
  .pdf-light-viewer-pdf-select .pdf-light-viewer-cat-thumb {
    width: 48px;
    height: 48px;
    margin-right: 5px; }

.pdf-light-viewer-embeded .pdf-light-viewer .pdf-light-viewer-features-bottom-toolbar-panel .pdf-light-viewer--icon-archive,
.pdf-light-viewer-embeded .pdf-light-viewer .pdf-light-viewer-features-top-panel .pdf-light-viewer--icon-archive {
  display: none; }

.pdf-light-viewer--clear {
  clear: both; }

.pdf-light-viewer-archive--one-page-with-thumbnails .pdf-light-viewer-pdf-categories {
  max-height: 700px;
  overflow: auto; }

.pdf-light-viewer-archive--one-page-with-thumbnails .pdf-light-viewer-grid-item-inner {
  text-align: center; }

.pdf-light-viewer-archive--one-page-with-thumbnails .pdf-light-viewer-pdf-select .pdf-light-viewer-selected:before {
  top: 50%; }

.pdf-light-viewer .pdf-light-viewer-search-form {
  display: inline-block; }
  .pdf-light-viewer .pdf-light-viewer-search-form input,
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-input {
    font-size: 17px !important;
    text-align: left;
    display: inline-block;
    width: 175px;
    border: none;
    padding: 3px 0 !important;
    margin: 0;
    border-bottom: 1px solid RoyalBlue;
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    background: transparent;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, RoyalBlue 4%) !important;
    background-position: -175px 0;
    background-size: 175px 100%;
    background-repeat: no-repeat;
    color: #777;
    box-shadow: none;
    border-radius: 0; }
    .pdf-light-viewer .pdf-light-viewer-search-form input:focus,
    .pdf-light-viewer .pdf-light-viewer-search-form .tt-input:focus {
      box-shadow: none;
      outline: none;
      background-position: 0 0; }
  .pdf-light-viewer .pdf-light-viewer-search-form input:first-of-type {
    border: none; }
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-query,
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-hint {
    outline: none; }
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-query {
    /* UPDATE: newer versions use tt-input instead of tt-query */
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-hint {
    color: #aaa; }
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-menu {
    /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
    text-align: left;
    width: 235px;
    margin-top: 2px;
    margin-left: -10px;
    padding: 6px 0;
    background-color: #fff;
    border-radius: 0 0 6px 6px;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  .pdf-light-viewer .pdf-light-viewer-search-form .tt-suggestion {
    padding: 3px 10px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer; }
    .pdf-light-viewer .pdf-light-viewer-search-form .tt-suggestion:hover, .pdf-light-viewer .pdf-light-viewer-search-form .tt-suggestion.tt-cursor {
      color: #fff;
      background-color: RoyalBlue; }
    .pdf-light-viewer .pdf-light-viewer-search-form .tt-suggestion p {
      margin: 0; }

.pdf-light-viewer .pdf-light-viewer-goto-page-input {
  border-radius: 0; }

.pdf-light-viewer .pdf-light-viewer-print-options-contaner {
  display: none; }

body .pdf-light-viewer-tips {
  margin-left: -12px;
  background: transparent;
  border-color: transparent; }
  body .pdf-light-viewer-tips .qtip-tip {
    background: #fff; }
  body .pdf-light-viewer-tips .qtip-content {
    background-image: linear-gradient(to bottom, #ffffff 0%, #fefefe 57%, #F6F6F6 100%);
    border-radius: 6px 6px;
    padding: 12px 24px;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
    body .pdf-light-viewer-tips .qtip-content ul {
      padding: 0;
      margin: 0; }
    body .pdf-light-viewer-tips .qtip-content li {
      list-style: none;
      padding: 0;
      margin: 0; }
    body .pdf-light-viewer-tips .qtip-content a {
      color: RoyalBlue;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .shadow {
  -webkit-transition: -webkit-box-shadow 0.3s;
  -moz-transition: -moz-box-shadow 0.3s;
  -o-transition: -webkit-box-shadow 0.3s;
  -ms-transition: -ms-box-shadow 0.3s;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .next-button {
  -webkit-border-radius: 0 15px 15px 0;
  -moz-border-radius: 0 15px 15px 0;
  -ms-border-radius: 0 15px 15px 0;
  -o-border-radius: 0 15px 15px 0;
  border-radius: 0 15px 15px 0; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .previous-button {
  -webkit-border-radius: 15px 0 0 15px;
  -moz-border-radius: 15px 0 0 15px;
  -ms-border-radius: 15px 0 0 15px;
  -o-border-radius: 15px 0 0 15px;
  border-radius: 15px 0 0 15px; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .previous-button-hover,
.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .next-button-hover {
  background-color: rgba(0, 0, 0, 0.2); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .previous-button-down,
.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .next-button-down {
  background-color: rgba(0, 0, 0, 0.4); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .pdf-light-viewer-magazine .even .gradient {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.2) 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .pdf-light-viewer-magazine .odd .gradient {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.15) 100%); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-viewport .pdf-light-viewer-magazine .page {
  background-color: #45484d;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.pdf-light-viewer.pdf-light-viewer--theme-dark.pdf-light-viewer-fullscreen {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#45484d+0,000000+100;Black+3D+%231 */
  background: #45484d;
  /* Old browsers */
  background: -moz-linear-gradient(top, #45484d 0%, #333 90%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #45484d 0%, #333 90%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #45484d 0%, #333 90%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#333',GradientType=0 );
  /* IE6-9 */ }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel {
  background-color: #000000;
  background-image: -webkit-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: -o-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: linear-gradient(to top left, #000000 0%, #414141 74%);
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel li:after {
    border-left: 1px solid transparent; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel li a,
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel li button,
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel li span {
    border: none;
    background: transparent;
    color: RoyalBlue; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel li span {
    color: #aaa; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel li .pdf-light-viewer-goto-page-input {
    border-bottom: 1px solid RoyalBlue;
    background: transparent;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, RoyalBlue 4%) !important;
    color: #aaa;
    box-shadow: none; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .pdf-light-viewer-features-bottom-panel {
  background-color: #000000;
  background-image: -webkit-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: -o-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: linear-gradient(to top left, #000000 0%, #414141 74%);
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .bx-wrapper {
  box-shadow: none;
  background: transparent;
  border-color: transparent; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .bx-wrapper .bx-pager a {
    background: #45484d; }
    .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .bx-wrapper .bx-pager a.active {
      background: RoyalBlue; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .bx-wrapper .bx-controls-direction a {
    color: RoyalBlue; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .pdf-light-viewer-slide {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background: #45484d;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  transition: 0.3s border; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .pdf-light-viewer-slide.current {
    border: 1px solid royalblue; }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .pdf-light-viewer-slide.thumb-hover {
    box-shadow: 0 3px 6px rgba(65, 105, 225, 0.3), 0 3px 6px rgba(65, 105, 225, 0.6);
    border: 1px solid rgba(65, 105, 225, 0.23); }
  .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .pdf-light-viewer-slide span {
    color: #aaa; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-magazine-thumbnails .pdf-light-viewer-features-top-nav-panel {
  border-radius: 4px; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .exit-message > div {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 10px; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form input,
.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form .tt-input {
  border-bottom: 1px solid RoyalBlue;
  background: transparent;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, RoyalBlue 4%) !important;
  color: #777; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form .tt-query {
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form .tt-hint {
  color: #aaa; }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form .tt-menu {
  background-color: #45484d;
  border-radius: 0 0 4px 4px;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form .tt-suggestion:hover, .pdf-light-viewer.pdf-light-viewer--theme-dark .pdf-light-viewer-search-form .tt-suggestion.tt-cursor {
  color: #45484d;
  background-color: RoyalBlue; }

body .pdf-light-viewer-tips.pdf-light-viewer--theme-dark .qtip-tip {
  background: #45484d; }

body .pdf-light-viewer-tips.pdf-light-viewer--theme-dark .qtip-content {
  background-color: #000000;
  background-image: -webkit-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: -o-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: linear-gradient(to top left, #000000 0%, #414141 74%);
  border-radius: 4px;
  color: #aaa;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  body .pdf-light-viewer-tips.pdf-light-viewer--theme-dark .qtip-content a {
    color: RoyalBlue; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-features-top-panel {
  border-radius: 4px 4px 0 0; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card {
  background-color: #000000;
  background-image: -webkit-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: -o-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: linear-gradient(to top left, #000000 0%, #414141 74%);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover h2 {
  background: RoyalBlue;
  color: #fff; }
  .pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover h2 a {
    color: #fff; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card:hover h2 small {
  color: #fff; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .card_circle {
  background-color: RoyalBlue; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner h2 {
  color: RoyalBlue; }
  .pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner h2 a {
    color: RoyalBlue; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .btn-pdf {
  border: 2px solid RoyalBlue;
  border-radius: 4px;
  color: RoyalBlue; }

.pdf-light-viewer-archive.pdf-light-viewer--theme-dark .pdf-light-viewer-grid .pdf-light-viewer-grid-item .pdf-light-viewer-grid-item-inner .btn-pdf:hover {
  background-color: RoyalBlue;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.pdf-light-viewer--theme-dark .pdf-light-viewer-search-container .pdf-light-viewer-search-input {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid RoyalBlue;
  color: RoyalBlue;
  box-shadow: none; }

.pdf-light-viewer--theme-dark .pdf-light-viewer-search-container .pdf-light-viewer-search-button {
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  color: RoyalBlue;
  font-weight: bold;
  font-size: 25px; }

.pdf-light-viewer--theme-dark .pdf-light-viewer-search-container-full .pdf-light-viewer-search-input {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid RoyalBlue;
  color: RoyalBlue;
  box-shadow: none; }

.pdf-light-viewer--theme-dark .pdf-light-viewer-search-container-full .pdf-light-viewer-search-button {
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  color: RoyalBlue;
  font-weight: bold;
  font-size: 25px; }

.pdf-light-viewer--theme-dark .pdf-light-viewer-search-results-found {
  color: RoyalBlue; }

.pdf-light-viewer--theme-dark .pdf-light-viewer-pdf-select {
  background-color: #000000;
  background-image: -webkit-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: -o-linear-gradient(bottom right, #000000 0%, #414141 74%);
  background-image: linear-gradient(to top left, #000000 0%, #414141 74%);
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .pdf-light-viewer--theme-dark .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a {
    color: RoyalBlue; }
    .pdf-light-viewer--theme-dark .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li a .pdf-light-viewer--counter {
      color: white;
      background-color: RoyalBlue;
      border-radius: 4px; }
  .pdf-light-viewer--theme-dark .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories > li .pdf-light-viewer-pdf-categories {
    border-bottom: none; }
  .pdf-light-viewer--theme-dark .pdf-light-viewer-pdf-select .pdf-light-viewer-pdf-categories .pdf-light-viewer-selected {
    color: RoyalBlue; }
